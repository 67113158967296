<template>
  <div>
    <CContainer>
      <nav-bar
        v-bind:headerName="categoryName"
        v-bind:cartTotal="cartTotal"
        @update-cartTotal="updateCartTotal"
      ></nav-bar>
      <hr class="mt-0" />

      <grid-menu
        v-bind:display="display"
        v-bind:cartTotal="cartTotal"
        @update-cartTotal="updateCartTotal"
      ></grid-menu>

      <div class="modal fade modal-p-bottom" id="categoryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body py-3">
              <category-lists
                v-bind:shopLineObjectId="shopLineObjectId"
              ></category-lists>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
    <footer class="fixed-bottom mb-6">
      <a
        class="categorybtn text-success"
        data-toggle="modal"
        data-target="#categoryModal"
      >
        <em class="fas fa-layer-group"></em>
      </a>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import CategoryLists from '../pickup/CategoryLists'
import NavBar from '../menu/NavBar'
import GridMenu from '../menu/GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    NavBar,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categoryName: '',
      cartLists: [],
      cartTotal: 0,
      display: 'grid',
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    categoryObjectId() {
      this.categoryName = sessionStorage.getItem('categoryName')
      return this.$route.params.categoryObjectId
    },
  },
  created() {
    this.getProduct()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
  },
  methods: {
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    updateCartTotal(total) {
      this.cartTotal = total
    },
    goToDetail(objectId) {
      this.$router.push('/smart-menu/' + objectId + '/detail')
    },
    getProduct(page = 1) {
      const uid = this.uid
      let categoryObjectId = this.categoryObjectId
      let params = {}

      if (categoryObjectId === '') {
        params = {
          shopObjectId: this.shopLineObjectId,
          page: page,
          limit: 50,
          searchVal: this.searchVal,
        }
      } else {
        params = {
          shopObjectId: this.shopLineObjectId,
          categoryObjectId: categoryObjectId,
          searchVal: this.searchVal,
          page: page,
          limit: 50,
        }
      }

      poscrm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/Shop/getproduct/view',
        params: params,
      }).then((res) => {
        this.products = res.data.data
        this.getCartTotal()
        console.log(this.products)
        /* this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage

        let pageCount = res.data.paginate.pageCount || 0

        if (pageCount > 1) {
          this.showPaginate = true
        } else {
          this.showPaginate = false
        } */
      })
    },
  },
  watch: {
    '$route.params.categoryObjectId'(val) {
      this.getProduct()
    },
  },
}
</script>
